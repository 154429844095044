<template>
  <div>
    <div class="min-h-screen">
      <nav class="bg-primary-400 px-2 fixed top-0 left-0 right-0 z-10">
        <div class="border-b border-primary-200 border-opacity-25">
          <div class="flex h-16 items-center justify-between">
            <div class="flex items-center">
              <div class="flex-shrink-0 mr-4 ml-4">
                <router-link
                  tag="a"
                  :to="'app-dashboard'"
                >
                  <img
                    class="h-8 w-auto"
                    :src="`/assets/${env.VUE_APP_APPLICATION}/favicon.svg`"
                    :alt="env.VUE_APP_APPLICATION"
                  />
                </router-link>
              </div>
              <div class="hidden md:block">
                <div class="flex items-baseline space-x-2">
                  <!-- Current: "bg-primary-700 text-white", Default: "text-white hover:bg-primary-500 hover:bg-opacity-75" -->
                  <!-- <a href="#" class="bg-primary-700 text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Tableau de Bord</a> -->
                  <!--eslint-disable-->
                  <router-link
                    v-for="(item, index) in sidebar"
                    v-if="(!item.roles || hasTabVisibilityRights(item.roles)) && (!item.allowed_apps || item.allowed_apps.includes(domain.application_id))"
                    :key="index"
                    tag="a"
                    :to="{path: item.path}"
                    class="text-gray-100 hover:bg-primary-700 hover:text-white hover:bg-opacity-75 rounded-md px-3 py-2 text-sm font-medium"
                    :class="{ 'bg-primary-700 text-white' : item.activePath && $route.fullPath.match(item.activePath) }"
                    active-class="bg-primary-700 text-white"
                  >
                    <i :class="'fa-solid ' + item.icon + ' flex-shrink-0 w-8 text-white mr-1'" />
                    {{ item.label }}
                  </router-link>
                  <!--eslint-enable-->
                </div>
              </div>
            </div>
            <div class="hidden md:block mr-4 flex-grow">
              <div class="pl-4 flex items-center md:pl-6 w-full">
                <!-- Domain dropdown -->
                <div
                  class="flex flex-grow"
                >
                  <DomainSelect :isFullWidth="true" />
                </div>

                <!-- Profile dropdown -->
                <div class="relative ml-3">
                  <t-dropdown
                    variant="rightside"
                  >
                    <div
                      slot="trigger"
                      slot-scope="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                        isShown
                      }"
                    >
                      <button
                        type="button"
                        class="flex max-w-xs items-center rounded-full bg-primary-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600"
                        :class="{ 'border-gray-300 bg-gray-500 text-white ': isShown }"
                        id="user-menu-button"
                        aria-label="Menu utilisateur"
                        aria-expanded="false"
                        aria-haspopup="true"
                        @mousedown="mousedownHandler"
                        @focus="focusHandler"
                        @blur="blurHandler"
                        @keydown="keydownHandler"
                      >
                        <span class="sr-only">Open user menu</span>
                        <!-- <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
                        <avatar :user="user" size="8" />
                      </button>
                    </div>
                    <div class="py-1 rounded-md shadow-xs">
                      <router-link
                        to="/app/me"
                        class="block px-4 py-2 mb-0 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        role="menuitem"
                      >
                        Mon compte
                      </router-link>
                      <a
                        href="#"
                        @click="logout"
                        class="block px-4 py-2 mb-0 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        role="menuitem"
                      >
                        Déconnexion
                      </a>
                    </div>
                  </t-dropdown>
                </div>
              </div>
            </div>
            <div class="flex md:hidden">
              <!-- Mobile menu button -->
              <button
                type="button"
                class="inline-flex items-center justify-center rounded-md bg-primary-600 p-2 text-primary-200 hover:bg-primary-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600"
                aria-controls="mobile-menu"
                aria-expanded="false"
                @click="showMenu = !showMenu"
              >
                <span class="sr-only">Open main menu</span>
                <!-- Menu open: "hidden", Menu closed: "block" -->
                <svg class="inline h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
                <!-- Menu open: "block", Menu closed: "hidden" -->
                <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!-- Mobile menu, show/hide based on menu state. -->
        <div v-if="showMenu" class="md:hidden" id="mobile-menu">
          <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
            <!-- Current: "bg-primary-700 text-white", Default: "text-white hover:bg-primary-500 hover:bg-opacity-75" -->
            <router-link
              tag="a"
              v-for="(item, i) of sidebar"
              :key="`mobile-${i}`"
              :to="{ path: item.path }"
              class="hover:bg-primary-500 hover:bg-opacity-75 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page"
              :class="{ 'bg-primary-700': item.activePath && $route.fullPath.match(item.activePath)}"
              active-class="bg-primary-700"
            >
              {{ item.label }}
            </router-link>
          </div>
          <div class="border-t border-primary-700 pb-3 pt-4">
            <div class="flex items-center px-5 w-full">
              <DomainSelect :isFullWidth="true"/>
            </div>
          </div>
          <div class="border-t border-primary-700 pb-3 pt-4">
            <div class="flex items-center px-5">
              <div class="flex-shrink-0">
                <avatar :user="user" size="8" />
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-white">{{ user.firstname + ' ' + user.lastname }}</div>
                <div class="text-sm font-medium text-primary-300">{{ user.email }}</div>
              </div>
              <!--
              <button type="button" class="ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-primary-600 p-1 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600">
                <span class="sr-only">View notifications</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
              </button>
              -->
            </div>
            <div class="mt-3 space-y-1 px-2">
              <router-link to="/app/me" class="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-primary-500 hover:bg-opacity-75">Mon compte</router-link>
              <a href="#" @click="logout" class="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-primary-500 hover:bg-opacity-75">Déconnexion</a>            </div>
          </div>
        </div>
      </nav>

      <main class="pt-16">
        <router-view @transfer="requestTransferred" @removeDomain="domainRemoved"></router-view>
      </main>

      <!--
      <footer>
        <div class="wrapper py-5 text-center text-sm text-gray-400">
          Copyright &copy; {{ env.VUE_APP_TITLE }}
        </div>
      </footer>
      -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseISO, formatDistanceToNowStrict } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { http } from '@/services/api.service'
import { socket } from '@/services/socket.service'
import Avatar from '@/components/Avatar'
import DomainSelect from '@/components/DomainSelect.vue'

export default {
  components: { Avatar, DomainSelect },
  computed: {
    ...mapGetters([
      'socketDisconnected',
      'user',
      'pendingRequests',
      'domain',
      'chats',
      'roles'
    ]),
    domain () {
      socket.emit('subscribe', { room: `${this.$store.getters.domain.uuid}/operators` })
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      isChatSidebarOpened: true,
      globalError: {
        title: null,
        text: null
      },
      sidebar: [
        {
          label: 'Accueil',
          path: '/app/dashboard',
          icon: 'fa-house'
        },
        {
          label: 'Calendrier',
          path: '/app/events',
          icon: 'fa-calendar'
        },
        {
          label: 'Actus',
          icon: 'fa-newspaper',
          path: '/app/articles'
          // children: [
          //   {
          //     label: 'NosDroits',
          //     icons: 'fa-newspaper',
          //     path: '/app/articles/universals'
          //   },
          //   {
          //     label: 'this.domain.label', // this.domain.label,
          //     icons: 'fa-newspaper',
          //     path: '/app/articles'
          //   }
          // ]
        },
        {
          label: 'FAQ',
          icon: 'fa-question',
          path: '/app/questions'
          // children: [
          //   {
          //     label: 'Types',
          //     icons: 'fa-book',
          //     path: '/app/questions/types',
          //     roles: ['admin', 'community-manager']
          //   },
          //   {
          //     label: 'Catégories',
          //     icons: 'fa-bookmark',
          //     path: '/app/questions/categories',
          //     roles: ['admin', 'director', 'community-manager']
          //   },
          //   {
          //     label: 'NosDroits',
          //     icons: 'fa-database',
          //     path: '/app/questions/universals'
          //   },
          //   {
          //     label: 'this.domain.label', // this.domain.label,
          //     icons: 'fa-database',
          //     path: '/app/questions'
          //   }
          // ]
        },
        {
          label: 'Sondages',
          path: '/app/surveys',
          icon: 'fa-poll'
        },
        {
          label: 'Conversations',
          path: '/app/chats',
          icon: 'fa-comments'
        },
        {
          label: 'Utilisateurs',
          activePath: '/app/users',
          path: '/app/users/user',
          icon: 'fa-user',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'Groupes',
          path: '/app/groups',
          icon: 'fa-users',
          roles: ['admin', 'director', 'community-manager']
          // allowed_apps: [4] // Tract only at 28/05/2024, all 30/05/2024
        },
        {
          label: 'Paramètres',
          path: '/app/parameters',
          icon: 'fa-sliders-h',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'Versions',
          path: '/app/versions',
          icon: 'fa-code-branch',
          roles: ['admin']
        }
      ],
      showMenu: false,
      env: process.env
    }
  },
  watch: {
    domain (newValue, oldValue) {
      this.showMenu = false
      if (newValue.uuid !== oldValue.uuid) {
        socket.emit('unsubscribe', { room: `${oldValue.uuid}/operators` })
      }
    },
    $route () {
      this.showMenu = false
    }
  },
  mounted () {
    // socket.emit('subscribe', { room: `${this.domain.uuid}/operators` })
    // this.getMyRequests()
    // this.getChats()
    // this.getPendingRequests()
    this.setUpSocketListeners()
  },
  destroyed () {
    socket.emit('unsubscribe', { room: `${this.domain.uuid}/operators` })
  },
  methods: {
    hasTabVisibilityRights (itemRoles) {
      if (Array.isArray(itemRoles)) {
        return itemRoles.some(role => this.roles.includes(role))
      }
      return false
    },
    to (route) {
      if (this.$route.path !== route) {
        this.$router.push(route)
      }
    },
    logout () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    },
    setUpSocketListeners () {
      // ! DEPRECATED
      socket.on('request:new', chat => {
        if (chat.assignee.uuid === this.user.uuid) {
          this.$browserPush.newRequest(chat)
          const chats = [...this.chats, chat]
          this.$store.commit('SET_MY_CHATS', chats)
        }
      })

      socket.on('chat:new', chat => {
        if (chat.assignee.uuid === this.user.uuid) {
          this.$browserPush.newRequest(chat)
          const chats = [...this.chats, chat]
          this.$store.commit('SET_MY_CHATS', chats)
        }
      })

      socket.on('request:closed', request => {
        this.$store.commit('SET_MY_REQUESTS', this.myRequests.filter(req => req.uuid !== request.uuid))
        if (this.$route.params.uuid === request.uuid) {
          this.$router.push('/app/dashboard')
        }
      })

      socket.on('chat:new-message', ({ request, message }) => {
        this.$browserPush.newMessage()
        if (this.$route.name === 'app-requests-chat' && request.uuid === this.$route.params.uuid) {
          return false
        }
        this.chats.map((chat, index) => {
          if (chat.uuid === request.uuid) {
            chat.hasNewMessage = true
            this.$set(this.chats, index, chat)
          }
        })
      })
    },
    getPendingRequests () {
      this.$store.dispatch('FETCH_PENDING_REQUESTS')
    },
    getMyRequests () {
      this.$store.dispatch('FETCH_MY_REQUESTS')
    },
    getChats () {
      this.$store.dispatch('FETCH_MY_CHATS')
    },
    openChat (chat) {
      if (this.$route.params.uuid !== chat.uuid) {
        chat.hasNewMessage = false
        this.$router.push({
          path: `/app/requests/${chat.uuid}`
        })
      }
    },
    requestTransferred (request) {
      this.$store.commit('SET_MY_REQUESTS', this.myRequests.filter(req => req.uuid !== request.uuid))
    },
    domainRemoved (domain) {
      this.getDomains()
    },
    getDomains () {
      http.get('/domain').then(({ data }) => {
        this.domains = data
        this.domainsSearchResults = data
      }).catch(err => {
        console.log(err)
      })
    },
    requestAccept (request) {
      if (window.confirm('Acceptation de la requête')) {
        http.post(`/request/${request.uuid}/accept`).then(({ data }) => {
          socket.emit('subscribe', { room: `${request.uuid}` })
          socket.emit('request:accepted', { request: data, domain: this.domain })
          this.$store.commit('SET_PENDING_REQUESTS', this.pendingRequests.filter(req => req.uuid !== data.uuid))
          this.$store.commit('SET_MY_REQUESTS', [...this.myRequests, data])
        }).catch(err => {
          console.log(err)
        })
      }
    },
    convertToRelativeDate (date) {
      return formatDistanceToNowStrict(parseISO(date), { locale: fr })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #f7f9fb;
}
.layout {
  display: flex;

}
.menu {
  padding: 1rem;
}

.sidebar {
  > .sidebar-content {
    background-color: transparent;
    box-shadow: none;
  }
}
.menu-list a {
  display: flex;
  align-items: center;

  span {
    &.icon {
      margin-right: 8px;
      opacity: .6;
    }
  }
  &:hover,
  &.is-active {
    span.icon {
      opacity: 1;
    }
  }
  &.is-active {
    font-weight: bold;
  }
}
.menu-label {
  color: #8590a5;
}

.layout-main {
  position: relative;
  overflow: hidden;
  margin: 0 0 0 260px;
  width: 100%;
  min-height: 100vh;
  overflow-anchor: auto;
}

#sidebar-chat {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.top-header {
  position: fixed;
  left: 260px;
  right: 260px;
  z-index: 999;
}
.has-top-header {
  padding-top: 150px;
}
.modal {
  z-index: 9999;
}
.sidebar-requests {
  position: relative;
  box-shadow: 5px 0px 13px 3px rgba(10, 10, 10, 0.1) !important;
  border-left: 1px solid #dbdbdb;

  .sidebar-requests--toggler {
    position: absolute;
    top: 10px;
    left: -20px;
  }

  .sidebar-content {
    // box-shadow: -5px 0px 13px 3px rgba(10, 10, 10, 0.1);
    // box-shadow: none;
    // border-left: 1px solid #eff2f7;

    &.is-light {
      background-color: #fff; //#f1f5f7;
      // background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
      // background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
    }
  }

  .menu-label {
    margin: 10px;
  }

  .requests-separator {
    background-color: #dbdbdb;
    height: 1px;
    border: 0;
    margin: 0;
  }
  .requests-group {
    &.requests-current {
      flex-grow: 1;
    }
    .request-emoji {
      margin: 10px 0;
      opacity: .2;
    }
    .request-confirm {
      background-color: hsl(141, 71%, 48%);
      color: white;
      width: 32px;
      text-align: center;
    }
    .request {
      display: flex;
      width: 100%;
    }

    .request-content {
      padding: 10px;
      font-size: .8rem;
      border-top: 1px solid rgba(16,22,26,.15);
      cursor: pointer;
      transition: background-color 400ms ease;
      display: flex;
      align-items: flex-start;
      width: 100%;

      &:hover {
        background-color: #f1f5f7;
      }

      .user-name {
        margin-left: 10px;
        line-height: 1.4em;
        flex-grow: 1;
      }

      .request-time {
        font-size: .7rem;
        min-width: 50px;
        text-align: right;
      }

      .request-icon {
        align-self: center;
      }
    }
  }
  .browser-status {
    padding-bottom: 10px;
    .item {
      display: flex;
      font-size: 11px;
      padding: 0 10px;
      justify-content: center;
      align-items: center;

      .item-label {
        flex-grow: 1;
      }
    }
  }
}

header {
  .navbar.is-fixed-top {
    left: 260px;
    right: 260px;

    .navbar-item.separator {
      background-color: #dbdbdb;
      height: 1px;
      border: 0;
      margin: 4px 0;
      padding: 0;
    }
  }
}
.sidebar-domain {
  padding: 1rem;
}
.sidebar-menu {
  flex-grow: 1;
}

//.sidebar-user {

//}
</style>
